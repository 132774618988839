@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.planning {
  &_to {
    height: 49px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 1.1em;
  }

  &_days {
    display: flex !important;
    justify-content: center;
  }

  &_modal {
    &_inner_row {
      height: 56px;
      display: flex;
      align-items: center;
      color: @black;

      &[role='button'] {
        padding: 0 1rem;
        justify-content: flex-end;
      }
    }

    &_label {
      width: 100%;
      font-size: 1.1em !important;
    }

    &_padding {
      padding: 32px 32px 0 32px !important;
    }

    &_padding_bottom {
      padding: 16px 32px 32px 32px !important;
    }
  }

  &_icon {
    display: flex;

    &_add_position {
      color: @color-blue;
      cursor: pointer;
      margin: 10px 0 10px 1rem;
    }

    &_remove_position {
      color: @red;
      cursor: pointer;
      margin: 10px 0 10px 1rem;
    }
  }

  &_position {
    width: 40%;

    &_wrapper {
      display: flex;
      padding-left: 10%;
      margin-bottom: 1rem;
    }
  }

  &_x {
    padding: 10px 8px;
  }

  &_quantity {
    width: 15%;

    &_input {
      & input {
        font-size: 1rem !important;
        max-height: 38px;
      }
    }

    &_error {
      width: 200px;
    }
  }

  &_row {
    margin-bottom: 1rem;
  }
}
