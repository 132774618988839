@import '~@/less/variables';

.homepage {
  height: 100vh;
  background-image: url("../../images/SMALL_EXT_ATA6743.jpg") !important;
  background-size: cover !important;
  overflow: hidden;

  &_headers {
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    h1,
    h3 {
      margin: 0.5rem 0 !important;
    }
  }

  &_overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}

.header {
  &_primary {
    font-size: 4rem;
    color: @color-off-white;
  }

  &_secondary {
    font-size: 2rem;
    font-weight: 400;
    color: @color-off-white;
  }
}
