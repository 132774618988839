@import '~@/less/main';

.strength_meter {
  border-radius: 4px;
  padding: 4px;

  &_container {
    width: 100%;
    margin-top: 8px;
  }

  &_weak {
    background-color: @red-negative;
  }

  &_poor {
    background-color: @password-yellow;
  }

  &_good {
    background-color: @green-positive;
  }

  &_very_strong {
    background-color: @password-green;
  }
}
