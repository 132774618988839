@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.list_layout {
    &_container {
      height: 50vh;
      overflow: scroll;
      overflow-x: hidden;
    }
  
    &_headers {
      text-transform: uppercase;
    }
  
    &_header_row {
      background-color: #ebebeb;
      font-size: 0.9em;
      font-weight: 600;
    }
  
    &_sticky_header {
      position: sticky;
      top: 0;
      z-index: 1;
    }
}

.ui.label:first-child {
    margin-left: 0em !important;
}
.displayEmployeesText {
  text-transform: capitalize;
  color: #D7AB73;
  font-size: 16px;
}