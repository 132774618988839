@import '~@/less/variables';
@import '~@/less/body';

.modal_parent_container {
  i {
    color: black !important;
  }
}

.wage_modal_header {
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #4a4a4a;
    margin-bottom: 10px;
  }

  h1 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    margin: 0;
    color: #000000;
    margin-bottom: 8px;
  }

  margin-bottom: 24px;
}

.modal_loader {
  top: 50% !important;
  transform: translateY(-50%) !important;
}


.wage_modal_action {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px !important;
  height: 2.375rem;

  button {
    margin-left: 24px !important;
    max-width: 120px !important;
    min-width: 100px !important;
  }
}

.wage_modal_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 24px;
}

// WAGE INFORMATION MODAL

.wage_modal_seccion {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;

  .form_item {
    width: 47% !important;

    label {
      margin-bottom: 4px !important;
      color: @black !important;
      font-weight: 600;
      font-size: 14px !important;
      opacity: 1 !important;
    }

    margin-bottom: 0px !important;
    opacity: 1 !important;
  }
}

.unchanged_form_item {

  input {
    color: #c0c0c0 !important;
  }

  >div {
    color: #c0c0c0 !important;
  }

}

// STEP PROGRESS TAB

.text_description {
  width: 100%;

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 600px;
  }
}

.step_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px 0px #00000040;

  .edit_title_step {
    width: 100%;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 6px;
  }
}

.skill {
  width: 215px;
  height: 215px;
  position: relative;
  display: flex;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg);
  }

  circle {
    fill: none;
    stroke: url(#GradientColor);
    stroke-width: 15px;
  }

  linearGradient {
    transform: rotate(90deg);
  }
}

.outer {
  width: 215px;
  height: 215px;
  border-radius: 50%;
  padding: 15px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15), -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.form_button {
  button {
    width: 100% !important;
    max-width: 100px !important;
  }
}

.inner {
  height: 185px;
  height: 185px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .statistic_container {
    .statistic_header {
      text-align: center;
      margin-bottom: 24px;

      h5 {
        margin: 0;
        margin-bottom: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #8794af;
      }

      h2 {
        margin: 0;
        font-size: 28px;
        font-weight: 700;
        color: black;
      }
    }

    .statistic_subheader {
      text-align: center;

      h6 {
        margin: 0;
        font-size: 12px;
        font-weight: 400;
        color: #8794af;
      }

      h4 {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
        color: black;
      }
    }
  }
}

.form_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: start;
  width: 100%;
  justify-content: space-between;
  margin-top: 18px;
  column-gap: 1.5rem;
  row-gap: 1rem;

  .form_item {

    label {
      margin-bottom: 4px !important;
      color: black !important;
      font-weight: 600;
      font-size: 14px !important;
      opacity: 1 !important;
    }

    margin-bottom: 0px !important;
    opacity: 1 !important;
  }
}