@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.actions_container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 40px;
  margin-bottom: 21px;
}

.position_selector_container {
  display: grid;
  place-self: end;
  min-width: 137px;
}
