@import '~@/less/main';

.modal_header {
  display: grid;

  >p {
    font-size: 14px !important;
    color: #4a4a4a !important;
    font-weight: 600;

    &:nth-child(1) {
      margin-bottom: 10px !important;
    }
  }

  >h4 {
    color: black !important;
    margin: 0 !important;
  }
}

.input_container {
  width: 100% !important;
}

.toggle_label {
  display: flex !important;
  justify-content: flex-start !important;

  p {
    margin: 0 !important;
  }
}

.checkbox {
  margin-left: 10px !important;
}

.modal_close {
  position: absolute;
  top: 20px;
  right: 22px;
  color: #1c1b1f !important;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
}

.tab_area {
  width: 75%;
  margin-bottom: 40px;
}

.body {
  display: grid;
  gap: 1rem;

  & input {
    height: 40px;
  }
}

.grid_2_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.grid_1_col {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.grid {
  display: grid;
}

.date_container {

  & input,
  textarea {
    padding: 0px 16px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    color: #212121;
  }

  & textarea {
    padding-top: 16px;
    resize: none;
  }
}

.main_modal {
  border-radius: 12px !important;
  padding: 20px 22px !important;
}

.action_container {
  display: grid;
  grid-auto-flow: column;
  margin-top: 40px;
  place-content: end;
  gap: 24px;

  & button {
    min-width: 120px;
  }
}

.checkbox_parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1rem;
}

.btn_primary {
  & button {
    background-color: #d7ab73 !important;
    color: #ffffff !important;
  }
}

.btn_default {
  & button {
    background-color: #616161 !important;
    color: #ffffff !important;
  }
}

.form_select {
  width: 100% !important;

  label {
    margin-bottom: 4px !important;
    color: @black !important;
    font-weight: 600;
    font-size: 14px !important;
    opacity: 1 !important;
  }

  margin-bottom: 0px !important;
  opacity: 1 !important;
}