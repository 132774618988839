.iconBtn {
    cursor: pointer;
}

.fileDisplayRoot {
    border: 0.5px solid #1975B3;
    border-radius: 7px;
    opacity: 0.50 !important;
    height: 80px;
    padding: 0px 24px !important;
}

.center {
    align-self: center;
}

.fileName {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
