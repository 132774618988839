.wage_dropdown_title{
    display: flex;
    justify-content: space-between;
    padding: 11px 1em;
    border-radius: 4px !important;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    font-size: 0.9em !important;
    border: none !important;
    min-height: 32px;
    cursor: pointer;
    position: relative;
    }

    .wage_position_search{
        display: flex;
        justify-content: space-between;
        padding: 11px 1em;
        border-radius: 4px !important;
        font-size: 0.9em !important;
        border: none !important;
        min-height: 32px;
        .dropdown_search_input{
            width: 80%;
            margin-right: 16px;
            max-width: 477px ;
        }  
        background: #FFFFFF;
    }

    .wage_container_body{
        background: #FFFFFF;
        width: 100%;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
        position: relative;
    }

    .wage_dropdown_hide{
        display: none;
    }

    .wage_list_container{
        max-height: 470px;
        overflow-y: auto;
        padding:0 16px ;
        padding-bottom: 54px;
    }
    .wage_position_list{
        display: flex;
        padding: 8px 16px;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        background-color: red;
        align-items: center;
        background: #FFFFFF;
        box-shadow:  0px 1px 3px -2px rgba(0, 0, 0, 0.25);
        height: 45px;
        margin-bottom: 2px;

        div{
            display: flex;
            align-items: center;
            span{
                margin-right: 16px;
                color: #9E9E9E;
            }
            .ui.checkbox{
                margin-left: 0.8rem !important;
            }
        }
    }

    .wage_list_button{
        position: absolute;
        width: 100%;
        padding-right:28px;
        padding-bottom: 8px;
        z-index: 900;
        background-color: #FFFFFF;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    #wage_list_button_footer{
            min-width:  fit-content !important;
            margin-right: 8px;
    }