@import '~@/less/variables';
@import '~@/less/body';

.link_aditional {
  min-width: 30px !important;
  max-width: 40px !important;
  display: flex;
  float: right;
  margin-right: 8px;
}

.normal_row {
  padding: 24px !important;
  background-color: #fff !important;
}

.row_highlight {
  padding: 24px !important;
  background-color: #F5F6F9 !important;
}

.form_container {
  width: 100%;
}

.form_sub_secction {
  display: flex;
  width: 100%;
  justify-content: flex-start !important;
  align-items: end;

  .form_item {
    label {
      margin-bottom: 0px !important;
      color: @black !important;
      font-weight: 600;
      font-size: 13px !important;
      opacity: 1 !important;
    }

    margin-right: 24px !important;
    margin-bottom: 0px !important;
    opacity: 1 !important;
  }
}

.form_button_hide {
  visibility: hidden;
  opacity: 0;
}

.form_button {
  width: 100% !important;
  max-width: 100px !important;
}

.form_button_save {
  margin: 0 !important;
  border-radius: 6px !important;
  color: white !important;
  font-weight: 600;
  background-color: #4d7297 !important;
  padding: 1rem 2rem !important;
}

.form_button_edit {
  margin: 0 !important;
  border-radius: 6px !important;
  box-shadow: 0px 0px 0px 1px #4d7297 inset !important;
  color: #4d7297 !important;
  font-weight: 600;
  background-color: white !important;
  padding: 1rem 2rem !important;
}

.submit_error_container {
  ul li:first-child {
    &::before {
      content: '' !important;
    }

    margin-left: 0 !important;
    list-style-type: none;
    /* Remove the list-style-type for the first item only */
  }
}

.notification_board {
  padding: 24px !important;
  background-color: #f3b1b15e !important;
  box-shadow: inset 1px 1px #f3b1b1, inset -1px -1px #f3b1b1;
}

.disabled_employee {
  color: #9e9e9e;
}