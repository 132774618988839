@import '~@/less/variables';
@import '~@/less/body';

.link_aditional {
  min-width: 30px !important;
  max-width: 40px !important;
  display: flex;
  float: right;
  margin-right: 8px;
}

.form_container {
  width: 100%;
}
.form_sub_secction {
  display: flex;
  width: 100%;
  justify-content: space-between !important;
  align-items: end;
  margin-bottom: 16px;
  padding: 0.2em 1em;

  .form_item {
    label {
      margin-bottom: 0px !important;
      color: @black !important;
      font-weight: 600;
      font-size: 13px !important;
      opacity: 1 !important;
    }
    margin-bottom: 0px !important;
    opacity: 1 !important;
  }

  .form_button_hide {
    visibility: hidden;
    opacity: 0;
  }

  .form_button {
    button {
      width: 100% !important;
      max-width: 100px !important;
    }
  }
  .form_button_basic {
    button {
      width: 100% !important;
      max-width: 100px !important;
      box-shadow: 0px 0px 0px 1px #4d7297 inset !important;
      color: #4d7297 !important;
      background-color: transparent !important;
    }
  }
}

.submit_error_container {
  ul li:first-child {
    &::before {
      content: '' !important;
    }
    margin-left: 0 !important;
    list-style-type: none; /* Remove the list-style-type for the first item only */
  }
}

.notification_board {
  background-color: #f3b1b15e !important;
  box-shadow: inset 1px 1px #f3b1b1, inset -1px -1px #f3b1b1;
}

.table_section {
  display: grid;
  gap: 1em;
  justify-items: center;
}
