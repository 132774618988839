@import '~@/less/main';

.modal {
  &_form {
    padding: 1.2rem;

    &_field_headers {
      padding-top: 0 !important;
      margin-bottom: 1rem !important;
    }

    h4 {
      padding: 0.5rem 0 0 0;
      margin: 0;
      font-size: 1.1em;
    }

    span {
      font-size: 0.9em;
    }
  }

  &_button {
    width: 49%;
  }
}
