@import '~@/less/main';

.assignment_header {
  margin-bottom: 2rem;
}

.dayoff {
  &_info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-transform: capitalize;
  }
}

.shift_cell {
  text-align: center;
  color: @blue-header !important;
}

.positive_cell {
  color: @red-negative !important;
}

.negative_cell {
  color: @black !important;
}

.radio_cell {
  display: flex !important;
  justify-content: space-around;
}

.contacts_wrapper {
  display: flex;
  flex-direction: column;
}

.reassign_header {
  font-weight: 600;
  color: @color-grey-dark;
  background-color: @light-grey;
  text-transform: uppercase;
}

.modal_segment {
  margin: 35px 25px 0 25px !important;
}

@border: 2px @light-grey solid;

.employee_row {
  cursor: pointer;
  color: @main-blue;
  padding: 8px 0 !important;
  font-size: 14px;
  border: @border;
}

.selected_employee {
  background-color: @color-column-light;
}

.no_fill {
  padding-left: 4px;
  text-transform: uppercase;
  font-weight: 700;
}

.details {
  width: 100%;
  margin-bottom: 16px !important;
}

.assignment_warning {
  color: @red-negative;
  font-size: 1.1em;
  margin-top: 1.5rem;
  padding: 8px 0;
}
