@import '~@/less/main';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';
@import './shift-block.less';
@import '../ShiftSwapModal/index.less';

@shaded-row-margin: 16px 0 0 0;
@row-margin: 8px 0 0 0;
@schedule-spacing: 8px 0;
@position-transition-margin-top: 8px;

.schedule {
  margin: 0 0 12px 0 !important;

  &_segment {
    padding: 0 !important;
    display: flex;
    background: transparent !important;
  }

  &_container {
    display: flex;
    flex-direction: column;
  }

  &_buffer {
    width: 100%;
    height: 8px;
    opacity: 0;
  }

  &_row {
    display: flex;
    margin: @schedule-spacing;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    align-items: center;

    * {
      flex: 1;
    }
  }

  &_control {
    &_wrapper {
      margin-left: 6px;
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 12px 0;
      padding: 6px 0;

      &_text {
        padding-left: 1em;
        font-weight: bold;
      }
    }

    &_switcher {
      display: flex;
      align-items: center;

      a {
        width: 12em;
        height: auto !important;
        text-align: center !important;
        box-shadow: none !important;
        font-size: 0.9em !important;
      }
    }
  }

  &_controls {
    display: flex;
    flex-wrap: nowrap;

    * {
      width: auto !important;
      flex: 1;
    }
  }

  &_dropdown,
  &_dropdown_filter {
    margin: 4px 16px;
    float: right;
    font-size: 0.9em !important;

    &_container {
      width: 25%;
    }
  }

  &_search_input {
    margin: 4px 16px 6px 16px;
    width: 100%;

    input {
      border: none !important;
    }
  }

  &_add_button {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    button {
      margin: 0 4px !important;
    }
  }

  &_conflict_filters {
    display: flex;
    align-items: center;

    button {
      height: 40px;
      width: 40px;
      margin: 0 8px;
      border: none;
      outline: none !important;
      cursor: pointer;
      border-radius: 20px;
      color: @color-grey-medium;
    }

    button:hover {
      color: @color-grey-dark;
    }
  }

  &_side_bar {
    display: flex;
    flex-direction: column;
  }

  &_week_label {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin: 0 2px;
    }
  }

  &_day_block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &_group_title {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
    margin: @schedule-spacing;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-weight: 700;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &_shaded {
      background-color: @light-grey;

      > .schedule_group_title {
        margin: @shaded-row-margin;
      }
    }
  }

  &_row,
  &_time_row,
  &_legend_row,
  &_group_title,
  &_control_header {
    background: white;
    box-shadow: @elevation-1;
  }

  &_time_row,
  &_legend_row {
    margin: 4px 0 0 0;
    padding: @schedule-spacing;
  }

  &_row_container {
    background-color: @light-grey;

    > .schedule_row {
      margin: @shaded-row-margin;
    }
  }

  // these styles could be improved in a few different ways,
  // which will be worked on soon
  &_time_row_fixed,
  &_legend_row_fixed {
    position: fixed;
    top: 0;
    z-index: 1;
    margin: 0;
  }

  &_time_row_fixed {
    box-shadow: 0 2px rgba(0, 0, 0, 0.24);
  }

  &_legend_row_fixed {
    z-index: 2;
    margin-left: -42px;
  }

  &_label_row {
    display: flex;
  }

  &_header_row {
    display: flex;
  }

  &_position_menu {
    background: transparent !important;
    box-shadow: none !important;
    border: none !important;

    &_item_sick-notice {
      color: @sick-notice-color !important;
    }

    &_item_shift-swap {
      color: @swap-request-color;
    }

    &_item_time-off {
      color: @time-off-color;
    }

    div,
    a {
      color: @blue-header !important;
      border: none !important;
      box-shadow: none !important;
      text-align: center !important;
      font-size: 0.9rem;

      &::before {
        background: none !important;
      }

      &:hover {
        background: lighten(@color-grey-lightest, 6%) !important;
        cursor: pointer;
      }

      &:first-of-type {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-of-type {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.position_group {
  color: @blue-header;
  text-align: center;
}

.area {
  &_container {
    display: flex;
    flex-direction: row;
    align-content: space-between;
  }

  &_legend {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: @row-margin;
    background: white;

    &_title {
      display: flex !important;
      align-items: center;
      justify-content: center;

      h6 {
        font-size: 1em;
      }
    }
  }

  &_legend_title,
  &_row,
  &_row_header,
  &_label_row {
    box-shadow: @elevation-1;
    margin: @schedule-spacing;
  }

  &_row,
  &_row_header {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &_legend_title,
  &_label_row {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &_row {
    display: flex;
    align-items: center;

    &_header {
      width: 100%;
      color: transparent;
    }
  }

  &_group_row {
    display: flex;
    flex-direction: column;
    margin: @row-margin;
    background: white;
  }

  &_label {
    width: 50px;
    margin: 16px 4px 8px 0;
    border-radius: 4px;
    position: relative;
    box-shadow: @elevation-1;
    background-color: @shift-block-highlight;

    &_text {
      display: block;
      font-size: 1.5em;
      color: #fff;
      min-width: 200px;
      position: absolute;
      top: calc(50%);
      text-align: center;
      left: 50%;
      transform: translate(-50%, -50%) rotate(270deg);
    }
  }

  &_label_row {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: @blue-header;
  }
}

.notice_modal {
  &_header {
    text-transform: uppercase;
    color: #fff;
    font-size: 1.3rem;

    &_timeoff {
      background-color: #778beb;
      padding: 2rem;
    }
  }

  &_shift {
    &_date {
      text-transform: uppercase;
    }
  }
}

.no_content {
  &_message {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_absolute {
      position: absolute;
      top: 150px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &_spacer {
    height: 100px;
  }
}

.next_sprint_modal {
  padding: 50px 0;
  text-align: center;
}

.shift_modal_dropdown {
  width: 100%;
}

.controls_spacer {
  width: 25%;
}

.ranges_label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div:not(.schedule_group_title_shaded) + .schedule_group_title_shaded {
  margin-top: @position-transition-margin-top;

  :first-child {
    margin-top: 0;
  }
}

div:not(.schedule_row_container) + .schedule_row_container {
  margin-top: @position-transition-margin-top;

  :first-child {
    margin-top: 0;
  }
}

.schedule_row_container + div:not(.schedule_row_container) {
  margin-top: @position-transition-margin-top;
}

.schedule_group_title_shaded + div:not(.schedule_group_title_shaded) {
  margin-top: @position-transition-margin-top;
}

.modal_header {
  &_time-off {
    background-color: @time-off-color;
    color: @off-white;
  }

  &_sick-notice {
    background-color: @sick-notice-color;
    color: @off-white;
  }
}
