@import '~@/components/Schedule/shift-block.less';
@import '~@/less/main';

.request_cell {
  padding: 12px 30px;
  text-transform: none;
  text-align: center;
  line-height: 12px;

  & > div > span {
    font-weight: 700;
    font-size: 11px;
  }
}

.swap {
  &_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.5rem 0;

    &_approve {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &_button {
      display: flex;
      justify-content: space-between;
      margin: 2rem;
    }
  }

  &_centered {
    align-self: center;
  }

  &_button {
    height: 40px !important;
    font-size: 12px !important;
    width: 47%;
  }
}

.table_row {
  border-bottom: none !important;
}

.divider {
  margin-right: 21px !important;
  margin-left: 21px !important;
}

.shift_swap_item {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  line-height: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 100%;
  border: none;
  background-color: @shift-block-main;
  cursor: default;
  margin-bottom: 8px;

  &_employee {
    text-transform: none;
    margin: 5px 0 3px 0;
  }

  &_label {
    background-color: @shift-block-highlight;
    color: @off-white;
  }
}

.change_cell {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.h3 {
  margin: 10px 0 !important;
}

.cost_difference_header {
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
}

.request_banner {
  width: 100%;
  height: 45px;
  margin-bottom: 10px !important;
  cursor: default;
  border-radius: 8px;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  padding: 12px;

  &_approved {
    border: 2px solid @green-positive;
    color: @green-positive;
  }

  &_denied {
    border: 2px solid @red-negative;
    color: @red-negative;
  }
}
