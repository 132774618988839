@import '~@/less/main';

.modal_scrolling_content {
  max-height: calc(100vh - 4rem) !important;
}


.profile {
  &_form {
    width: 100%;

    &_dob {
      margin: 1em 0 !important;

      input {
        font-weight: 400 !important;
      }
    }

    &_number_row {
      margin: 8px 0 !important;
    }

    &_remove_number {
      display: flex !important;
      justify-content: flex-end !important;
      align-items: center !important;

      button {
        .circular-button();
      }
    }
  }

  &_phone_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 12px !important;

    button {
      .circular-button();
    }
  }
}

.start_date {
  input {
    font-weight: 400 !important;
  }
}

.position {
  &_wrapper {
    &_checkboxes {
      border: 1px solid rgb(224, 225, 226);
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      border-radius: 2px;
    }
  }

  &_right {
    display: flex !important;
    justify-content: flex-end;
    position: fixed;
    bottom: 2em;
    right: 3em;
  }
}

.disabled_header {
  margin-bottom: -0.5rem;
}

.calendar_container,
.calendar_container_error {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 0px 0px;
  gap: 8px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  .ui.segment {
    flex: 1;
  }
}

.icon_calendar {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  height: 34px;
  padding-right: 0px !important;
}



.calendar_container_error {
  border: 1px solid @red;
  border-radius: 2px;
}

.alert_message {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: @red;
  padding: 0px !important;
}

.lsrp_input {
  display: flex !important;
  justify-content: flex-end;

  .ui.input {
    width: 100% !important;
  }
}