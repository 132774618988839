@import '~@/less/variables';
@import '~@/less/body';


.form_container {
  width: 100%;
}

.form_sub_section {
  display: flex;
  width: 100%;
  justify-content: flex-start !important;
  align-items: end;
  padding-bottom: 1rem;

  .form_item {
    label {
      margin-bottom: 0px !important;
      color: @black !important;
      font-weight: 600;
      font-size: 13px !important;
      opacity: 1 !important;
    }

    margin-right: 1rem !important;
    opacity: 1 !important;

    &.step_input {
      margin-left: 15px !important;
    }
  }
}

.last_form_subsection {
  padding-bottom: 0;
}

.form_button_hide {
  visibility: hidden;
  opacity: 0;
}

.form_button {
  width: 100% !important;
  max-width: 100px !important;
}

.form_button_save {
  margin: 0 !important;
  border-radius: 6px !important;
  color: white !important;
  font-weight: 600;
  background-color: #4d7297 !important;
  padding: 1rem 2rem !important;
}

.form_button_edit {
  margin: 0 !important;
  border-radius: 6px !important;
  box-shadow: 0px 0px 0px 1px #4d7297 inset !important;
  color: #4d7297 !important;
  font-weight: 600;
  background-color: white !important;
  padding: 1rem 2rem !important;
}