.tabs_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-self: start;
  & > * {
    position: relative;
    display: grid;
    place-content: center;
    font-size: 18px;
    color: #9E9E9E;
    height: 52px;
    cursor: pointer;
    user-select: none;
    &::after {
      content: '';
      transform: scaleX(0);
      transform-origin: left;
      transition: transform 0.2s ease;
    }
  }
}

.tab_selected {
  color: #000000;
  &::after {
    content: '';
    position: absolute;
    transform: scaleX(1);
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #D7AB73;
  }
}
