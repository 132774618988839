@import '~@/less/main';

.submenu_wrapper{
  display: grid;
}

.opened {
  background-color: #d7ab73;
}

.submenu_header {
  display: grid;
  grid-template-columns: 40px 1fr;
  column-gap: 0.4em;
  &:hover {
    background-color: #d7ab73;
  }
}

.submenu_items_wrapper {
  background-color: #f2f2f2;
  display: grid;
  transform-origin: top;
  transition: transform .3s ease;
}

.submenu_item {
  padding: 0.4em;
  min-height: 56px;
  align-items: center;
  cursor: pointer;
  display: grid;
  align-items: center;
}

.submenu_child {
  color: #4a4a4a;
  padding-left: 1.2em;
  font-size: 0.8em;
  &:hover {
    background-color: #ebebeb;
    color: black;
  }
}

.submenu_child_active {
  background-color: #ebebeb;
  color: black;
}
.submenu_items_collapsed {
  transform: scaleY(0);
  height: 0;
}
