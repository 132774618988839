@import '~@/less/main';

.timesheet {
  &_highlight {
    color: @blue-header;
  }

  &_confirm_icon {
    margin-top: 0.5em !important;
  }

  &_cell {
    text-align: center;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &_subcell {
    display: block;
    width: 100%;
    text-align: center;
  }
}

.totals {
  &_card {
    &_stick {
      position: sticky;
      right: 3em;
      top: 1em;
    }

    &_tablet {
      margin-top: 16px;
    }

    &_container {
      padding: 0 0 0 3em;
      margin: 0 -0.25rem;
      flex-grow: 1;
      transition: width ease-in @sidebar-transition-length;
    }

    &_grid {
      padding: 15px;
      justify-content: space-between;
    }

    &_cost {
      flex-grow: 1;

      &_button {
        margin: 1.5em 0;
      }
    }
  }
}

.details {
  &_subcell {
    width: 100%;
  }

  &_label {
    max-width: 100%;
    text-align: center;

    &_info {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-left: 8px;
    }
  }
}

.breakdown {
  font-size: 0.75em;
  color: @blue-header;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;

  &_title {
    padding: 4px;
    margin-bottom: 0;
  }

  &_headers {
    font-size: 1.1em;
    color: @blue-header;
  }

  &_content {
    width: 550px;
  }
}
