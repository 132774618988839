@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.api {
  &_form {
    padding: 30px 15% 0 15%;
  }

  &_copy {
    max-height: 3.5rem;
  }

  &_success {
    &_msg {
      color: #05c46b;
      font-size: 0.9rem;
    }
  }
}
