@import '~@/less/variables';
@import '~@/less/mixins';

.add_button_container {
  .button-bar();

  &_wide {
    left: calc(@wide-menu-width + @segment-padding);
  }

  &_slim {
    left: calc(@slim-menu-width + @segment-padding);
  }
}

.save_button_container {
  right: @segment-padding;
  .button-bar();
}
