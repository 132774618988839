@import '~@/less/variables';

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  img {
    height: 110px;
    border-radius: 50%;
  }
}
