@import '~@/less/main';

.flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p {
    margin-bottom: 10px;
    font-weight: 600;
  }

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0 0 1.5rem 0 !important;
  }
}

.employee_rates_modal_container {
  height: 90vh;
  max-height: 90vh;
  overflow: auto;
}