@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.gen {
  &_modal {
    p {
      font-size: 1.1em !important;
    }

    &_padding {
      padding: 32px 32px 0 32px !important;
    }

    &_padding_bottom {
      padding: 16px 32px 32px 32px !important;
    }
  }
}

.week_dates {
  margin-top: 10px;
  color: @color-primary;
  font-size: 1.1em;
  font-weight: 700;
}
