@import '~@/less/main';

.app_content {
  padding-right: 0 !important;
  padding-left: 260px !important;
  transition: padding-left @sidebar-transition-length ease-in !important;

  &_thin {
    padding-left: 100px !important;
  }
}

