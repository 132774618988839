@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.tab_menu {
  margin-top: 0 !important;

  div {
    margin: 0 !important;
  }
}

.tab_container {
  padding-top: 1.5rem !important;
}

.dropdown_payperiod {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  text-transform: capitalize !important;
  text-align: left !important;
  align-items: flex-start !important;
  padding-right: 0 !important;

  p {
    font-size: 14px !important;
    font-weight: 600 !important;
  }

  .text {
    margin-right: 20px !important;
  }
}

.filter_container {
  max-width: 20rem;
  margin-right: 2rem;
  width: 18rem;
  display: flex;
  align-items: flex-start;

  >div {
    padding-left: 0.5rem !important;
    min-height: 2.5rem !important;
  }
}

.filter_row {
  display: flex;

}

.dropdown_payperiod p {
  margin: 0 !important;
  color: #4a4a4a;
  margin-bottom: 8px !important;
}

.loader {
  position: unset !important;
}