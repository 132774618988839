@import '~@/less/main';

.table {
  box-shadow: none !important;
  border: 0 !important;

  th {
    background-color: #fff !important;
    color: #212121 !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  td {
    text-align: center !important;
  }

  .row_selected {
    font-weight: bold;
  }
}