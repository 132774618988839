.dept_select_wrapper {
  display: grid;
  margin-right: 1em;
  width: 200px;
  position: relative;
  & > div {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1em;
    background-color: rgb(255, 255, 255);
    padding: .5em 1em;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    cursor: pointer;
    user-select: none;
  }
  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & label {
    font-weight: bold;
    color: rgb(74, 74, 74);
  }
}

.drop_down_body {
  position: absolute;
  display: grid;
  bottom: -180%;
  z-index: 3;
  left: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0.5em 1em;
  transform: scaleY(1);
  transition: transform .1s linear;
  transform-origin: top;
  height: fit-content;
  & > div {
    display: grid;
    grid-template-columns: 1fr 1em;
  }
}

.hidden {
  transform: scaleY(0);
}

.super_hidden {
  display: none !important;
}

.disabled {
  opacity: 0.45;
  color: rgba(0, 0, 0, 0.87);
}
