.pagination_parent {
  display: flex;
  width: 100%;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}

.parent_checkbox_all {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
}