@import '~@/less/main';
@list-header-background: #ebebeb;

.list_layout {
  &_container {
    height: 50vh;
    overflow: scroll;
    overflow-x: hidden;
  }

  &_headers {
    text-transform: uppercase;
  }

  &_header_row {
    background-color: @list-header-background;
    font-size: 0.9em;
    font-weight: 600;
  }

  &_sticky_header {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.button_container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 2em;
  transition: width ease-in @sidebar-transition-length;
}

.highlighted_row {
  background-color: @highlighted-item !important;
}