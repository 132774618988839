@import '~@/less/main';

.notice_numbers {
  color: @color-primary;
}

.details {
  &_input {
    width: 100%;
    height: 100%;

    & > input {
      height: 100%;
    }
  }
}

.sched_details {
  &_cell {
    display: flex;
  }

  &_table {
    &_department {
      font-weight: 700;
      letter-spacing: 1px;
    }
  }
}

.swap_modal {
  padding-left: 3px;
}

.popup_item {
  cursor: pointer;
}

.schedule {
  &_bottom_bar {
    width: calc(100% + 6em);
    height: 5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: sticky;
    bottom: 0;
    background: white;
    margin-left: -3em;
    margin-top: 1em;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 0 3em;

    &_div {
      margin: 1rem 0;
      width: 555px;
    }
  }

  &_headers {
    color: @color-grey-medium;
    font-size: 1.4em;
  }

  &_list {
    margin-top: 1.5rem !important;

    h1 {
      margin: 0 0 16px 0 !important;

      &:not(:first-of-type) {
        margin: 16px 0 !important;
      }
    }
  }
}

.filter_button {
  padding-left: 25%;
}

h1 > svg {
  margin-left: 8px;
}

.title_input {
  box-shadow: @elevation-1 !important;
  font-weight: 700;
  font-size: 1.4rem !important;
  outline: none;
}

.edit_icon {
  padding-left: 8px;
}

.table_cell {
  text-align: center;
  width: 30%;
}

.incomplete_notification_icon {
  color: @red-negative;
  margin-right: 4px;
}

.csv_button {
  color: #777;
}
