@import '~@/less/main';


.phone_inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  position: relative;

}

.phone_body {
  margin-top: 8px;
  display: grid;
  gap: 16px;
}

.inputs {
  padding: 0px 16px;
  border-radius: 6px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  color: #212121;
}


.profile_form_remove_number {
  position: absolute;
  right: -85px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.field_item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  >div {
    height: 40px;
  }

  & label {
    margin-bottom: 8px;
  }

  & input {
    height: 40px;
  }
}