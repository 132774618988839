@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.search_header {
  display: flex;
  margin: 0 0 24px 0;
  padding: 16px 0 0 0;

  &_input {
    flex: 5;
    margin: 0 32px 0 0;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
}

.add_button {
  float: right;
}
