.alert_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    margin-top: 1rem;
    text-decoration: underline;
    color: #303030;
  }

  a:hover {
    text-decoration: underline;
    color: #000;
  }

  strong {
    color: #9b2226;
  }

}

.notifier {
  padding: 0.25rem;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #ebebeb;
  }

}

.alert_count {
  position: absolute;
  top: 0;
  right: 0rem;
  color: #fff;
  font-size: 0.625rem;
  padding: 0.075rem 0.35rem;
  min-width: 1rem;
  min-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 50%;
  box-shadow: 1px 1px 2px 2px rgba(192, 192, 192, 0.3);
}