@import '~@/less/main';

.header {
  padding: 0 0 0 0 !important;
  width: 100%;

  p {
    margin-bottom: 10px;
    font-weight: 600;
    color: '#4A4A4A';
  }

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0 0 0.5rem 0 !important;
  }

  span {
    font-weight: 600;
    color: '#4A4A4A';
  }
}

.modal_header {
  display: grid;

  >p {
    font-size: 14px !important;
    color: #4a4a4a !important;
    font-weight: 600;

    &:nth-child(1) {
      margin-bottom: 10px !important;
    }
  }

  >h3 {
    color: black !important;
    margin: 0 !important;
  }
}

.modal_content {
  padding: 32px !important;
}

.tab_area {
  width: 100%;
  margin-bottom: 1.5rem;
}

.phone_inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  position: relative;
}

.phone_body {
  margin-top: 8px;
  display: grid;
  gap: 16px;
}

.inputs {
  padding: 0px 16px;
  border-radius: 6px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  color: #212121;
}

.profile_body {
  display: grid;
  gap: 22px;
  padding-right: 100px;
  width: 100% !important;

  & label {
    margin-bottom: 8px;
  }

  & input {
    height: 40px;
  }
}

.emergency_body {
  display: grid;
  gap: 22px;
  width: 100% !important;

  & label {
    margin-bottom: 8px;
  }

  & input {
    height: 40px;
  }
}

.emergency_body {
  display: grid;
  gap: 22px;

  & label {
    margin-bottom: 8px;
  }

  & input {
    height: 40px;
  }
}

.grid_2_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.grid {
  display: grid;
}

.date_container {

  & input,
  textarea {
    padding: 0px 16px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    color: #212121;
  }

  & textarea {
    padding-top: 16px;
    resize: none;
  }
}

.modal {
  max-width: 888px;
  border-radius: 16px !important;
}

.action_container {
  display: flex;
  margin-top: 22px;
  width: 100% !important;
  justify-content: flex-end;
  padding-right: 0 !important;
  margin-right: 0 !important;
  gap: 24px;

  & button {
    min-width: 120px;
  }
}

.btn_primary {
  & button {
    background-color: #d7ab73 !important;
    color: #ffffff !important;
  }
}

.btn_default {
  & button {
    background-color: #616161 !important;
    color: #ffffff !important;
  }
}

.profile_form_remove_number {
  position: absolute;
  right: -85px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cert_block {
  display: grid;
  grid-template-columns: repeat(3, auto);
  padding: 32px 24px;
  border: 1px solid #bcc3d2;
  border-radius: 4px;
  row-gap: 22px;
  margin-top: 8px;

  &>div {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
  }
}

.tab_inner_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tab_content {
  height: 34rem;
  max-height: 34rem;
  width: 100%;
  overflow-y: auto;
  padding: 0 0.25rem 0.25rem;
}

.main_container {
  padding-bottom: 0 !important;
  padding-top: 0 !important;

}

.cert_main {
  display: grid;
  gap: 15px;
  width: 100%;
}