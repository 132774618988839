.root {
    height: 288px;
    border: 1px dashed #979797;
    flex-direction: column !important;
    border-radius: 4px;
}

.cloud {
    margin-bottom: 16px;
}

.text {
    color: #979797;
}