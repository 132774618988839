@import '~@/less/variables';
@import '~@/less/body';

.login {
  height: 100vh !important;
  background: @black;

  img {
    margin-top: 10vh;
    margin-bottom: 4vh;
  }

  h1,
  h3 {
    color: white;
    margin: 0;
    text-align: center;
    font-weight: 400;
  }

  h3 {
    font-size: 1.1rem;
    margin-top: 2vh !important;
    margin-bottom: 4vh !important;
  }

  &_support_items {
    margin-top: 2vh;

    * {
      text-align: center;
    }

    p {
      color: white;
    }

    a {
      display: block;
      width: 100%;
    }

    a,
    a:visited,
    a:focus,
    a:active,
    a:hover {
      color: @color-primary !important;
    }
  }

  &_copyright {
    width: 100%;
    height: 1rem;
    position: fixed;
    bottom: 1rem;

    span,
    a {
      font-size: 0.75rem;
      padding-right: 0.9rem;
      padding-left: 0.1rem;
    }

    span,
    a,
    a:visited,
    a:focus,
    a:active,
    a:hover {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  &_divider {
    background: @color-primary;
  }

  &_subheader {
    color: white;
    margin: 16px 0 !important;
  }

  &_form {
    button {
      width: 100%;
      margin: 16px 0;
      color: white;
      background: none;
      border: none;
      text-transform: uppercase;
      text-align: end;
      font-size: 1.1em;
      cursor: pointer;
      outline: none !important;
    }

    button:hover {
      opacity: 0.8;
    }
  }

  &_info_segment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px !important;
    text-align: center;

    img {
      margin-bottom: 16px;
    }

    a {
      color: @color-primary;

      &:hover,
      &:visited {
        opacity: 0.8;
      }
    }
  }

  &_form_column {
    background: @color-grey-dark;
  }

  &_form_segment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 64px !important;
    text-align: center;
  }
}
