@import '~@/less/main';

.grid {
  display: grid;
}

.date_container {

  & input,
  textarea {
    padding: 0px 16px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    color: #212121;
  }

  & textarea {
    padding-top: 16px;
    resize: none;
  }
}

.cert_block {
  display: grid;
  grid-template-columns: repeat(3, auto);
  padding: 32px 24px;
  border: 1px solid #bcc3d2;
  border-radius: 4px;
  row-gap: 22px;
  margin-top: 8px;

  &>div {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
  }
}

.cert_main {
  display: grid;
  gap: 15px;
  width: 100%;
}