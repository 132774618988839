.body_menus {
  border: none;
}
.body_menus_item_color {
  color: #000 !important;
}
.page_header {
  font-size: 2rem;
  text-transform: capitalize;
}
.alternating_rows *:nth-child(even) {
  background: #fafafa;
}
.form_headers {
  margin-bottom: 0.5rem;
  color: #4a4a4a;
  font-size: 1.1rem;
  font-weight: 700;
}
.form_subheaders {
  margin-bottom: 5px;
  color: #2b5797;
  margin-top: 0;
  font-weight: 400;
}
.form_inputs {
  width: 100%;
  height: 3rem;
}
.form_uppercase {
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.modal_headers {
  font-size: 1rem;
}
.modal_divider {
  margin-left: 3% !important;
  margin-right: 3% !important;
}
.modal_header {
  margin-bottom: 1rem;
  width: 100%;
}
.confirm_wrapper > button:last-of-type {
  margin: 0 !important;
}
.confirm_button {
  width: 12rem;
  margin-right: 16px !important;
}
.flex_group {
  display: flex;
  align-items: center;
  max-width: 75%;
}
.flex_group_field {
  flex: 1 !important;
}
.checkboxes {
  display: inline-block;
  padding-right: 2rem;
  padding-top: 1rem;
  margin-bottom: 1rem !important;
}
.checkbox_subheader {
  font-weight: 200;
  color: #2b5797;
  width: 100%;
  margin-bottom: 0;
}
.search_bar {
  width: 50%;
}
.search_bar > input {
  width: 70%;
  display: inline-block;
  height: 4rem;
  border: none !important;
  border-color: transparent;
  font-size: 1.6rem;
}
.search_bar > input::placeholder {
  font-size: 1.6rem;
}
.add_button {
  float: right;
  font-size: 1rem;
  border-radius: 2px;
  margin-left: 2rem;
  margin-right: 0.7rem;
  display: flex;
}
@media only screen and (min-width: 1257px) and (max-width: 1520px) {
  .add_button {
    margin-right: 1.6rem;
  }
}
.search_hr {
  width: 85%;
}
@media only screen and (min-width: 1521px) and (max-width: 1840px) {
  .search_hr {
    width: 80%;
  }
}
@media only screen and (min-width: 1257px) and (max-width: 1520px), only screen and (max-width: 1256px) {
  .search_hr {
    width: 70%;
  }
}
.search_icon {
  margin-right: 7px;
  font-size: 1.5 rem;
  color: #cccbca;
}
.button_icon {
  margin-right: 7px;
}
.table_row {
  background-color: #fafafa;
  font-size: 1.4rem;
  margin: 16px 0;
  display: table-row;
  border-bottom: solid white 10px;
  padding: 8px;
}
.table_icon {
  float: right;
  cursor: pointer;
  font-size: 1.3rem;
}
.table_icon:hover {
  color: #212f43;
}
.table_headers_gold {
  margin-bottom: 1rem;
  color: #d7ab73;
  font-size: 1.5rem;
  font-weight: 700;
}
.table_header {
  background-color: #4a4a4a;
  color: #fff;
}
.table_subheader {
  background-color: #2b5797;
  color: #fff;
  text-transform: uppercase;
}
.table_dropdowns {
  font-size: 1.1rem !important;
}
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search_wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 32px 0 0;
}
.search_wrapper .input {
  flex: 1;
}
.search_wrapper input {
  border: none !important;
  border-color: transparent;
  font-size: 1.6rem;
}
.error_class,
.error_message {
  color: #ee5253 !important;
  margin: 8px 0 0 0 !important;
}
.error_message {
  margin: 16px 0 !important;
  font-size: 1.1em;
}
.error_day_picker {
  margin-top: 4px !important;
}
.error_time_picker {
  margin-top: 12px !important;
}
.error_message_icon {
  font-size: 1.8em;
  margin-right: 16px;
}
.flex_wrapper {
  display: flex;
}
.flex_between {
  display: flex;
  justify-content: space-between;
}
.flex_center {
  display: flex;
  justify-content: center;
}
.border_right {
  border-right: 1px solid rgba(191, 191, 191, 0.87);
}
.icon_margin {
  margin-right: 15px;
}
.chevrons {
  cursor: pointer;
}
.position_cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  text-transform: uppercase;
  font-size: 10px;
}
.position_cell:focus {
  outline: none;
}
.position_shift_1 {
  border: 1px solid #81c784;
}
.position_shift_1_request {
  color: #fff;
  background-color: #81c784;
}
.position_shift_1 div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.position_highlight_1 {
  color: #81c784;
  font-weight: bold;
}
.position_cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  text-transform: uppercase;
  font-size: 10px;
}
.position_cell:focus {
  outline: none;
}
.position_shift_2 {
  border: 1px solid #2b5797;
}
.position_shift_2_request {
  color: #fff;
  background-color: #2b5797;
}
.position_shift_2 div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.position_highlight_2 {
  color: #2b5797;
  font-weight: bold;
}
.position_cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  text-transform: uppercase;
  font-size: 10px;
}
.position_cell:focus {
  outline: none;
}
.position_shift_3 {
  border: 1px solid orange;
}
.position_shift_3_request {
  color: #fff;
  background-color: orange;
}
.position_shift_3 div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.position_highlight_3 {
  color: orange;
  font-weight: bold;
}
.position_cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
  text-transform: uppercase;
  font-size: 10px;
}
.position_cell:focus {
  outline: none;
}
.position_shift_4 {
  border: 1px solid red;
}
.position_shift_4_request {
  color: #fff;
  background-color: red;
}
.position_shift_4 div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.position_highlight_4 {
  color: red;
  font-weight: bold;
}
.set_height {
  height: 42px;
  cursor: pointer;
}
.search_input {
  max-height: 30px;
  border: #d4d4d5 2px solid;
  border-radius: 5px;
}
.search_input > input {
  border: none !important;
}
.requests_status {
  text-transform: capitalize;
}
.requests_status_denied {
  color: #ff3b30 !important;
}
.popup_menu {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
}
.popup_menu div,
.popup_menu a {
  color: #1d6ea9 !important;
  border: none !important;
  box-shadow: none !important;
  text-align: center !important;
  font-size: 0.9rem;
}
.popup_menu div::before,
.popup_menu a::before {
  background: none !important;
}
.popup_menu div:hover,
.popup_menu a:hover {
  background: #f9f9f9 !important;
  cursor: pointer;
}
.popup_menu div:first-of-type,
.popup_menu a:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.popup_menu div:last-of-type,
.popup_menu a:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.dropdowns {
  min-height: 32px !important;
}
.clickable {
  cursor: pointer;
}
.radio {
  margin: 0 0 0 8px;
  font-size: 1.1em !important;
  z-index: 0;
}
.wage-container .ui.breadcrumb .active.section {
  font-weight: 600 !important;
  color: #616161 !important;
}
.wage-container .list_layout_header .row {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.wage-container .list_layout_body .row div {
  text-align: center;
}
.wage-container .list_layout_body .row div:first-child {
  text-align: left;
}
.wage-container .list_layout_body .row:nth-child(even) {
  background-color: #F5F6F9 !important;
  font-weight: 400;
  font-size: 14px;
}
.top_row_container {
  padding-bottom: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.top_row_breadcrumb {
  color: #4a4a4a;
  height: 35px;
  padding: 9px 0;
}
.top_row_breadcrumb_link > a {
  color: #4a4a4a !important;
}
.top_row_profile_container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* Table variables */
/* New design colors */
.u_remove_margin {
  margin: 0 !important;
}
.u_margin_bottom_small {
  margin-bottom: 1.5rem !important;
}
.u_margin_bottom_medium {
  margin-bottom: 4rem !important;
}
.u_margin_bottom_big {
  margin-bottom: 8rem !important;
}
.u_margin_top_tiny {
  margin-top: 1em !important;
}
.u_margin_top_small {
  margin-top: 2rem !important;
}
.u_margin_top_medium {
  margin-top: 4rem !important;
}
.u_margin_top_big {
  margin-top: 8rem !important;
}
.u_margin_top_huge {
  margin-top: 10rem !important;
}
.flex_row_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.remove_padding_top {
  padding-top: 0 !important;
}
.header_black {
  color: #4a4a4a !important;
}
.tab_spacer {
  margin-top: calc(1em + 16px) !important;
}
.fields {
  border-radius: 4px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
  font-size: 0.9em !important;
  border: none !important;
  min-height: 32px;
}
.fields:hover,
.fields:focus {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.fields .active {
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: none;
}
.circular-button {
  width: 24px;
  height: 24px;
  padding: 0 !important;
  margin: 4px 2px !important;
}
.button-bar {
  position: fixed;
  bottom: 2em;
  transition: left ease-in 0.2s;
}
.cell {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  cursor: pointer;
}
.cell:not(:first-of-type) {
  margin-top: 8px !important;
}
.cell_header {
  text-transform: uppercase;
  width: 100%;
  display: inline-block;
  text-align: center;
  position: relative;
  top: calc((100% - 1.1rem) / 2);
}
.cell_bottom {
  background-color: #a7b2ca;
  color: #fafafa !important;
  padding: 4px;
  border-radius: 0 0 4px 4px;
  text-transform: uppercase;
}
.cell_top {
  background-color: rgba(167, 178, 202, 0.3);
  color: rgba(74, 74, 74, 0.6) !important;
  padding: 4px;
  border-radius: 4px 4px 0 0;
}
.date_picker {
  border-radius: 4px !important;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}
.date_picker button {
  margin: 0 !important;
}
:global #requests_start,
:global #requests_end,
:global #date_of_birth {
  height: 32px;
}
:global #requests_start,
:global #requests_end {
  border-radius: 0 !important;
  box-shadow: none !important;
}
#calendar_id input {
  box-shadow: none !important;
  text-align: center;
}
