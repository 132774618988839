.shift_block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &_item {
    width: 80%;
    max-width: 64px;
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    color: @color-grey-medium;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.11);
    overflow: hidden;

    &_weekday {
      background-color: @shift-block-main;
    }

    &_weekend {
      background-color: @shift-block-main-weekend;
    }

    &_label {
      color: @off-white;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &_weekday {
        background-color: @shift-block-highlight;
      }

      &_weekend {
        background-color: @shift-block-highlight-weekend;
      }

      &_multi {
        color: #000;
      }
    }
  }

  span {
    padding: 4px 4px;
  }

  // center single item shift blocks
  &_item_single {
    padding-bottom: 16px !important;
  }

  &_item_open {
    font-weight: bold;
  }

  &_item_small {
    display: block;
    width: 16px !important;
    height: 16px !important;
    overflow: hidden;
    border-radius: 50%;
    flex: initial !important;

    &_sick {
      background-color: @sick-notice-color !important;
    }

    &_time_off {
      background-color: @time-off-color !important;
    }

    &_teal {
      background-color: @swap-request-color;
    }

    &_violet {
      background-color: @time-off-color;
    }

    &_red {
      background-color: @sick-notice-color;
    }

    * {
      display: none;
    }
  }

  &_item_big {
    border-radius: 4px;
    height: 80px;
    justify-content: flex-end;
  }

  &_item_text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_big,
  &_pad {
    width: 56px;
  }

  &_popup {
    display: flex;
    flex-direction: column;
    font-size: 1.15em;

    div {
      margin: 8px 0;
    }
  }

  &_pop {
    padding: 16px 32px !important;
  }
}

.block_wrapper {
  position: relative;
  max-width: 80px;
  display: flex;
  justify-content: center;
}

.shift_badge {
  // ensures that badge is under the schedule sticky header
  z-index: 0 !important;
}
