@import '~@/less/main';

.modal {
    padding: 2rem 5rem;
}

.p {
    font-size: 22px;
    color: @completion-modal-green;
    font-weight: 700;
}

.text_container {
    padding: 16px 0 !important;
}

.btn_container {
    padding: 0 13px !important;
}

.btn {
    margin: 4px 0;
    margin-bottom: 24px !important;
}