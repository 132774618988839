@import '~@/less/main';

.conflict_button {
  display: flex;
  flex-direction: column;
  text-align: center;

  span {
    font-size: 0.9em;
  }
}
