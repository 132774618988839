@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.pill {
  border-radius: 5px !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
  &:focus {
    border-color: rgba(34, 36, 38, 0.15) !important;
  }
  text-align: initial !important;
  padding: 17px 12px;
}

.selectedPill {
    background-color: #D7AB73 !important;
    >p {
        color: #FFFFFF
    }
}

.unSelectedPill {
    background-color: #FFFFFF !important;
}

.header {
    font-weight: 700;
    margin-bottom: 0;
}
