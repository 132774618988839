@import '~@/less/main';

.swage_input{
    
    width: 100% !important;
}

.wagegrid_table_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3{
        margin: 0;
    }
}


.layout{
    &_1col{
        min-width: 70% !important;
        max-width: 70% !important;
        &:first-of-type{
            min-width: 30% !important;
            max-width: 30% !important;
        }
    }
    &_2col{
        min-width: 35% !important;
        max-width: 35% !important;
        &:first-of-type{
            min-width: 30% !important;
            max-width: 30% !important;
        }
    }
    &_3col{
        min-width: 25% !important;
        max-width: 25% !important;
    }
    &_4col{
        min-width: 21.25% !important;
        width: 21.25% !important;
        max-width: 21.25% !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_5col{
        min-width: 17% !important;
        max-width: 17% !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_6col{
        min-width: 14.15% !important;
        max-width: 14.15% !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_7col{
        min-width: 12.14% !important;
        max-width: 12.14% !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_8col{
        min-width: 10.62% !important;
        max-width: 10.62% !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_9col{
        min-width: 9.4% !important;
        max-width: 9.4% !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_10col{
        min-width: 8.5% !important;
        max-width: 8.5% !important;
        padding: 3px !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_11col{
        min-width: 7.72% !important;
        max-width: 7.72% !important;
        padding: 3px !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_12col{
        min-width: 7.08% !important;
        max-width: 7.08% !important;
        padding: 2px !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_13col{
        min-width: 6.53% !important;
        max-width: 6.53% !important;
        padding: 2px !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_14col{
        min-width: 6.07% !important;
        max-width: 6.07% !important;
        padding: 2px !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }
    &_15col{
        min-width: 5.66% !important;
        max-width: 5.66% !important;
        padding: 2px !important;
        &:first-of-type{
            min-width: 15% !important;
            max-width: 15% !important;
        }
    }

}

.wage_table_container {
    overflow-x: auto;
    width: 100%;
}

#wage_grid_table{

   border-radius: 6px;

  th,td{
    font-size: 12px;
  }
  td{
    background: white;
    input{
        text-align: end !important;
        font-size: 12px !important;
    }
  }
  th{
    font-weight: 400;
    font-size: 14px;
    background: #EBEBEB;
  }

  th:first-child,
  td:first-child {
    min-width: 186px ;
    position: sticky;
    left: 0;
    z-index: 1;
    text-align: left;
  }

    /* Set the width of the rest of the columns */
    th:not(:first-child),
    td:not(:first-child) {
      min-width: 112px;
      width:     116px;
      max-width: 120px;
    }    

    tr:nth-child(even) {
        td{
            background-color: #F5F6F9 !important; 

        }
      }

    .lsrp_grid{
        width: 100%;
        padding-left: 14px;
        padding-right: 12px;
        display: flex; 
        justify-content: space-between;
    }
    .grid_dates_range{
        font-weight: 600;
    }
}

.submit_error_container{

    ul li:first-child {
        &::before{
            content: '' !important;
        }
        margin-left: 0 !important;
        list-style-type: none; /* Remove the list-style-type for the first item only */
      }
}