@import '~@/less/main';

.list_layout {
  &_container {
    border: 1px solid #f5f6f9;
    border-radius: 5px;
    min-height: 9.75rem;
  }

  &_header_row {
    background-color: #fff;
  }

  &_body {
    box-shadow: none;
  }

  &_row {
    height: 50px;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &_warning {
      background-color: #fdf2ef;
    }
  }
}

.modal {
  max-width: 888px;
  border-radius: 16px !important;
}

.modal_content {
  padding: 24px !important;
}

.table {
  box-shadow: none !important;
  border: 0 !important;

  th {
    background-color: #fff !important;
    color: #212121 !important;
    font-weight: 600 !important;
    text-align: center !important;
  }

  td {
    text-align: center !important;
  }
}

.row_highlighted {

  td {
    background-color: #FDF2EF;
  }
}

.header {
  padding: 0 0 0 0 !important;

  p {
    margin-bottom: 10px;
    font-weight: 600;
    color: '#4A4A4A';
  }

  h3 {
    font-weight: bold;
    font-size: 1.5rem;
    margin: 0 0 1.5rem 0 !important;
  }
}

.row {
  padding: 0 0 0 0 !important;
}

.modal_loader {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.pay_period {
  text-align: end;
  padding-bottom: 1rem !important;
  color: '#4A4A4A';
}

.right_content {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-size: 24px;
    font-weight: 700;
  }
}

.button_container {
  display: flex;
  justify-content: space-between;

  button {
    min-width: 100px;
  }
}