@import '~@/less/main';

.modal_header {
  display: grid;

  >p {
    font-size: 14px !important;
    color: #4a4a4a !important;
    font-weight: 600;

    &:nth-child(1) {
      margin-bottom: 10px !important;
    }
  }

  >h3 {
    color: black !important;
    margin: 0 !important;
  }
}

.content {
  padding: 0px !important;
}

.input_container {
  width: 100% !important;
}

.toggle_label {
  display: flex !important;
  justify-content: flex-start !important;

  p {
    margin: 0 !important;
  }
}

.checkbox {
  margin-left: 10px !important;
}

.modal_close {
  position: absolute;
  top: 20px;
  right: 22px;
  color: #1c1b1f !important;
  font-size: 12px;
  cursor: pointer;
  user-select: none;
}

.tab_area {
  width: 75%;
  margin-bottom: 40px;
}

.inputs {
  padding: 0px 16px;
  border-radius: 6px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  color: #212121;
}

.body {
  display: grid;
  gap: 22px;

  & input {
    height: 40px;
  }
}

.grid_2_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.flex_row {
  display: flex;
  flex-direction: row;
}

.grid {
  display: grid;
}

.date_container {

  & input,
  textarea {
    padding: 0px 16px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    color: #212121;
  }

  & textarea {
    padding-top: 16px;
    resize: none;
  }
}

.period_container {
  display: flex;
  margin-top: 0.5rem;

  & input {
    margin-right: 0.5rem !important;
    min-width: none;
    width: 60%;
  }

}

.main_modal {
  border-radius: 16px !important;
  padding: 32px !important;
}

.action_container {
  display: grid;
  grid-auto-flow: column;
  margin-top: 40px;
  place-content: end;
  gap: 24px;

  & button {
    min-width: 120px;
  }
}

.btn_primary {
  & button {
    background-color: #d7ab73 !important;
    color: #ffffff !important;
  }
}

.btn_default {
  & button {
    background-color: #616161 !important;
    color: #ffffff !important;
  }
}

.profile_form_remove_number {
  position: absolute;
  right: -85px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.cert_block {
  display: grid;
  grid-template-columns: repeat(3, auto);
  padding: 32px 24px;
  border: 1px solid #bcc3d2;
  border-radius: 4px;
  row-gap: 22px;
  margin-top: 8px;

  &>div {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
  }
}

.cert_main {
  display: grid;
  gap: 40px;
}