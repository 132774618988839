@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.areas {
  &_form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 4rem;

    &_fields {
      margin-bottom: 1rem;
    }
  }
}
