.screen_size_message {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4a4a4a !important;

  h2 {
    text-align: center;
    color: white !important;

    * {
      margin: 2rem 0;
    }
  }

  img {
    width: 80%;
    position: absolute !important;
    bottom: 0;
    right: 0;
  }
}
