@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.day_picker {
  &_button {
    flex-grow: 1;
  }

  &_big_buttons {
    height: 56px;
    width: 56px;
  }
}
