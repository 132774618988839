@import '~@/less/main';


.emergency_body {
  display: grid;
  gap: 22px;
  width: 100% !important;

  & label {
    margin-bottom: 8px;
  }

  & input {
    height: 40px;
  }
}


.grid_2_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.grid {
  display: grid;
}

.inputs {
  padding: 0px 16px;
  border-radius: 6px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border: none;
  outline: none;
  color: #212121;
}