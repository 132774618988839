@import '~@/less/main';

.profile {
  &_form {
    width: 100%;

    &_dob {
      margin: 1em 0 !important;

      input {
        font-weight: 400 !important;
      }
    }

    &_number_row {
      margin: 8px 0 !important;
    }

    &_remove_number {
      display: flex !important;

      button {
        .circular-button();
      }
    }
  }

  &_phone_header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 12px !important;

    button {
      .circular-button();
    }
  }
}

.start_date {
  input {
    font-weight: 400 !important;
  }
}

.position {
  &_wrapper {
    &_checkboxes {
      border: 1px solid rgb(224, 225, 226);
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      border-radius: 2px;
    }
  }

  &_right {
    display: flex !important;
    justify-content: flex-end;
    position: fixed;
    bottom: 2em;
    right: 3em;
  }
}

.disabled_header {
  margin-bottom: -0.5rem;
}
