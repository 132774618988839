.menu_logo {
  height: 116px;
  position: relative;
  margin: 2em 0;

  img {
    max-width: 68% !important;
    position: absolute !important;
    top: 5%;
    right: 16%;
    bottom: 5%;
    left: 16%;
    margin: auto;
    max-height: 72px;
  }
}

.menu_item_collapse {
  width: 100%;
  text-align: center;
  font-size: 1rem !important;
  align-self: flex-end;
}

.floating_toast_container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  width: auto;
}