.tables_container {
  width: 100% !important;

  & table {
    margin: 0 !important;
  }

  & thead th {
    background-color: #eaeaea !important;
  }

  & tr {
    height: 60px !important;
    box-sizing: border-box;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0px 0 15px !important;
}

.header_employee_search {
  width: 250px !important;
}

.header_item {
  width: 150px !important;
}

.table_header_fixed {
  min-width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.table_scrollable_x {
  max-width: 100%;
  overflow: auto;

  & td {
    color: #4a4a4a;
  }
}

.long_column {
  min-width: 20em;
}

.medium_column {
  min-width: 16em;
}

.txt_small {
  font-size: 12px;
}

.table {
  width: 100% !important;
  border-right: unset !important;

  & thead {
    position: sticky;
    top: 0;
  }
}

.iconBtn {
  cursor: pointer;
  user-select: none;
}

.fixed_h {
  text-wrap: nowrap;
}

.sort_action {
  cursor: pointer;
  user-select: none;
}

.notification_board {
  padding: 24px !important;
  background-color: #f3b1b15e !important;
  box-shadow: inset 1px 1px #f3b1b1, inset -1px -1px #f3b1b1;
}