@import '~@/less/main';
@row-header-background: #ebebeb;

.employee_panel_container{}
.employee_panel_header{
    background-color: @row-header-background;
    font-size: 1.01em;
    font-weight: 400;
}
.employee_panel_body{
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #D9DDE5;
    border-radius: 0px 0px 6px 6px;
    padding: 24px 16px;
}
.employee_row_title{
    font-size: 1.01em;
    font-weight: 400;
    color: #777777;
    padding: 16px 0;
}
.employee_row_list{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    padding-bottom: 16px;

    .employee_label{
        min-width: 93px;
        background: #F5EBDD !important;
        border-radius: 8px !important;
        font-weight: 400 !important;
        color: #777777 !important;
        font-size: 1.01em !important;
        text-align: center;
    }
}
.employee_row_footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 36px;
    padding-top: 24px;
    font-weight: 600 !important;
    font-size: 1.02em !important;
    color: #D7AB73 !important;
}