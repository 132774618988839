@import '~@/less/breakpoints';
@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';

.button_icon {
  margin-right: 8px;
}

.search_icon {
  margin-right: 8px;
  font-size: 1.5rem;
  color: #cccbca;
}

.employees {
  &_card {
    border: none !important;
    background-color: @shift-block-main !important;
    border-radius: 4px !important;

    &_header {
      color: @off-white !important;
      font-weight: 500 !important;
      padding-bottom: 8px;
    }

    &_content {
      color: @off-white;
      background: @shift-block-highlight !important;
      border-top: none !important;
      border-radius: 0 0 40px 40px !important;
    }
  }
}

.image_container {
  height: 128px;
  width: 128px;
  align-self: center;
}

.main_section {
  display: grid;
  justify-items: center;
  gap: 1em;
}

.empty_state {
  margin: 2em 0;
}
