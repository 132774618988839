@import '~@/less/variables';
@import '~@/less/body';

.planning {
  &_grid {
    margin: 0 !important;
  }

  &_mini_button {
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;

    &:nth-of-type(2) {
      margin-left: 4px !important;
    }
  }
}
