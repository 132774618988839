@import '~@/less/main';

.profile_body {
  display: grid;
  gap: 22px;
  padding-right: 100px;
  width: 100% !important;

  & label {
    margin-bottom: 8px;
  }

  & input {
    height: 40px;
  }
}

.grid_2_col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.grid_4_col {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}

.grid {
  display: grid;
}

.date_container {

  & input,
  textarea {
    padding: 0px 16px;
    border-radius: 6px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    color: #212121;
  }

  & textarea {
    padding-top: 0.5rem;
    resize: none;
  }
}