@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

.input {
  border-radius: 5px !important;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3) !important;
  &:focus {
    border-color: rgba(34, 36, 38, 0.15) !important;
  }
}