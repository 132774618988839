@import '~@/less/variables';
@import '~@/less/top_row';
@import '~@/less/body';
@import '~@/less/breakpoints';

@payrollCategoryWidth: 50px;

.payroll {
  &_add_item {
    display: flex;
    justify-content: space-between;
    margin-top: 16px !important;
  }

  &_button_row {
    margin: 32px 0;

    &_col {
      display: flex !important;
      align-items: center !important;
    }
  }

  &_form {
    &_field:not(:first-of-type) {
      margin-top: 1rem;
    }
  }

  &_table_row_header {
    cursor: pointer;
  }

  &_category_header {
    width: @payrollCategoryWidth;
    text-align: center;
  }

  &_checkmark_container {
    display: flex;
    justify-content: space-between;
  }

  &_checkmark {
    width: @payrollCategoryWidth;
  }

  &_categories_row_header {
    display: flex;
    flex: 1;
    justify-content: space-between;
    margin: 0 !important;
  }
}

.max_unit {
  text-transform: capitalize;
}
