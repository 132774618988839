@import '~@/less/main';

h1 {
  margin-top: 4rem;
}

.organizations {
  &_form {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.positions {
  &_form {
    &_fields {
      margin-bottom: 1rem;
      padding-right: 1rem;
    }

    &_wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &_ot {
    &_rules {
      margin-top: 1rem;
    }

    &_dropdown {
      flex-grow: 2;
    }
  }

  &_mini_button {
    width: 32px !important;
    height: 32px !important;
    padding: 0 !important;
    margin: 8px 0 0 8px !important;
  }
}

.rotations {
  &_frequency_row {
    display: flex;
    align-items: center;

    div:nth-child(2) {
      width: 25% !important;
      margin: 0 16px;
    }

    span {
      padding: 0 1em;
    }
  }

  &_role_dropdown {
    margin-right: 1rem;
  }

  &_form {
    margin-top: 4rem;

    &_dropdown {
      width: 60% !important;

      &_active {
        color: green;
      }

      &_inactive {
        color: @color-grey-dark;
      }

      &_wrapper {
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
      }

      &_area {
        width: 60%;
      }

      &_arrow {
        margin: 10px 0;
        width: 60%;
        text-align: center;
      }

      &_x {
        display: flex;
        color: @color-blue;
        margin: 1rem;
        font-size: 1.3rem;
        cursor: pointer;
      }
    }

    &_fields {
      width: 49%;
    }
  }

  &_header {
    color: @color-primary;
  }

  &_search_hr {
    width: 70%;
  }

  &_arrow_wrapper {
    position: relative;
  }

  &_side_border {
    position: absolute;
    top: 1.68rem;
    bottom: 24px;
    left: calc(~'60% + 43px');
    width: 1.58em;
    border-right: 2.5px solid black;
    border-bottom: 2.5px solid black;
  }

  &_wrapper {
    &_checkboxes {
      border: 1px solid rgb(224, 225, 226);
      width: 100%;
      padding: 1rem 2rem;
      margin-top: 1rem;
      border-radius: 2px;
    }
  }
}

.overtime_rules {
  &_headers {
    padding: 1rem 0;
    text-align: center;
  }

  &_wrapper {
    width: 100%;
  }
}

.break_buttons {
  > button {
    .circular-button();
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    width: 25%;
    align-items: flex-end;

    > p {
      margin: 0;
    }
  }
}
