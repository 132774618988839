.tables_container {
  display: grid;
  grid-template-columns: 192px auto;

  & table {
    margin: 0 !important;
  }

  & thead th {
    background-color: #eaeaea !important;
  }

  & tr {
    height: 60px !important;
    box-sizing: border-box;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0px 0 15px !important;
}

.header_employee_search {
  width: 250px !important;
}

.header_item {
  width: 150px !important;
}

.employee_name_cell {
  max-width: 8rem !important;
  width: 100%;
  max-height: 60px !important;
  overflow: hidden !important;
  text-align: center;
}

.employee_name_container {
  display: flex;
  height: 60px !important;
  align-items: center;
  justify-content: space-between;
}



.table_header_fixed {
  min-width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


.table_scrollable_x {
  max-width: 100%;
  overflow: auto;

  & td {
    color: #4a4a4a;
  }
}

.long_column {
  min-width: 20em;
}

.medium_column {
  min-width: 16em;
}

.txt_small {
  font-size: 12px;
}

.t_left {
  border-right: unset !important;

  & thead {
    position: sticky;
    top: 0;
  }
}

.t_right {
  border-left: unset !important;
}

.iconBtn {
  cursor: pointer;
  user-select: none;
}

.fixed_h {
  text-wrap: nowrap;
}

.sort_action {
  cursor: pointer;
  user-select: none;
}

.row_inactive {
  td {
    color: #9e9e9e !important;
  }
}