*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

#root {
  height: 100%;
}

section {
  height: 100%;
}

body {
  box-sizing: border-box;
  margin-bottom: 0;
  padding-bottom: 0;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-small-medium {
  margin-bottom: 2.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

.u-margin-top-small {
  margin-top: 2rem !important;
}

.u-margin-top-medium {
  margin-top: 4rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.homepage {
  display: inline-block;
}

.login__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login__left-pane {
  vertical-align: center;
}

.login__left-pane__image {
  height: 50%;
  width: 75%;
  margin: auto;
}

.login__left-pane__paragraph {
  color: grey;
}

.login__right-pane {
  height: 100%;
  text-align: center;
}

.login__right-pane__hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1.5px solid #b9975b;
  margin: 0.5rem;
  padding: 0;
}

.login__right-pane__header {
  text-transform: uppercase;
  color: #fff;
  font-size: 1.5rem;
  margin: 0;
}

.login__right-pane__subheader {
  font-size: 0.8rem;
}

.user-page__menu {
  background-color: grey;
  height: 100vh;
}

.user-page__menu--image {
  height: 9rem;
  width: 16rem;
}

.is_slim {
  .menu {
    width: 56px !important;
  }

  .menu_item_text {
    display: none;
  }

  .app_content {
    padding: 32px 32px 32px 88px;
  }
}
